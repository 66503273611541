export const menuFormConfig = [{
		label: '名称',
		prop: 'name'
	},
	{
		label: '图标',
		prop: 'icon'
	},
	{
		label: '路由',
		prop: 'uri'
	},
	{
		label: '权限类型',
		prop: 'type'
	},
	{
		label: '排序',
		prop: 'sort',
		type: 'number'
	},
	{
		label: '启用状态',
		prop: 'status',
		type: 'select',
		option: [{
			label: '禁用',
			value: 0
		}, {
			label: '启用',
			value: 1
		}]
	}
]

export const btnFormConfig = [{
		label: '名称',
		prop: 'name',
		type: 'selectInput',
		option: ["新建", "编辑", "删除", "查看"]
	},
	{
		label: '按钮样式',
		prop: 'icon',
		// type: 'select',
		// option: [
		// 	{
		// 		label: "default",
		// 		value: "default"
		// 	},{
		// 		label: "primary",
		// 		value: "primary"
		// 	}, {
		// 		label: "success",
		// 		value: "success"
		// 	},
		// 	{
		// 		label: "info",
		// 		value: "info"
		// 	},
		// 	{
		// 		label: "warning",
		// 		value: "warning"
		// 	},
		// 	{
		// 		label: "danger",
		// 		value: "danger"
		// 	}
		// ]
	},
	{
		label: '按钮事件',
		prop: 'uri',
		type: 'selectInput',
		option: ["newData", "editData", "delData", "viewData"]
	},
	{
		label: '按钮位置',
		prop: 'code',
		type: 'select',
		option: [{
			label: '右侧',
			value: "右侧"
		}, {
			label: '顶部',
			value: "顶部"
		}]
	},
	{
		label: '排序',
		prop: 'sort',
		type: 'number'
	},
	{
		label: '启用状态',
		prop: 'status',
		type: 'select',
		option: [{
			label: '禁用',
			value: 0
		}, {
			label: '启用',
			value: 1
		}]
	},
	{
		label: '权限类型',
		prop: 'type'
	}
]
export const menuFormDataProp = {
	name: '',
	icon: '',
	uri: '',
	pid: '0',
	sort: 0,
	type: 1,
	status: 1,
	permission: 1
}
export const btnFormDataProp = {
	name: '',
	icon: '',
	uri: '',
	pid: '0',
	sort: 0,
	type: 2,
	status: 1,
	permission: 1
}
export const menuRules = {
	name: [{
		required: true,
		message: '请输入菜单名称',
		trigger: 'blur'
	}],
	icon: [{
		required: true,
		message: '请选择菜单图标',
		trigger: 'blur'
	}],
	status: [{
		required: true,
		message: '请设置启用状态',
		trigger: 'change',
	}]
}

export const btnRules = {
	name: [{
		required: true,
		message: '请输入按钮名称',
		trigger: 'blur'
	}],
	status: [{
		required: true,
		message: '请设置启用状态',
		trigger: 'change',
	}],
	icon: [{
		required: true,
		message: '请设置按钮样式',
		trigger: 'change',
	}],
	uri: [{
		required: true,
		message: '请设置按钮点击事件',
		trigger: ['change','blur'],
	}]
}

export const iconListArr=[
	"el-icon-platform-eleme",
	"el-icon-eleme",
	"el-icon-delete-solid",
	"el-icon-delete",
	"el-icon-s-tools",
	"el-icon-setting",
	"el-icon-user-solid",
	"el-icon-user",
	"el-icon-phone",
	"el-icon-phone-outline",
	"el-icon-more",
	"el-icon-more-outline",
	"el-icon-star-on",
	"el-icon-star-off",
	"el-icon-s-goods",
	"el-icon-goods",
	"el-icon-warning",
	"el-icon-warning-outline",
	"el-icon-question",
	"el-icon-info",
	"el-icon-remove",
	"el-icon-circle-plus",
	"el-icon-success",
	"el-icon-error",
	"el-icon-zoom-in",
	"el-icon-zoom-out",
	"el-icon-remove-outline",
	"el-icon-circle-plus-outline",
	"el-icon-circle-check",
	"el-icon-circle-close",
	"el-icon-s-help",
	"el-icon-help",
	"el-icon-plus",
	"el-icon-check",
	"el-icon-close",
	"el-icon-picture",
	"el-icon-picture-outline",
	"el-icon-picture-outline-round",
	"el-icon-upload",
	"el-icon-upload2",
	"el-icon-download",
	"el-icon-camera-solid",
	"el-icon-camera",
	"el-icon-video-camera-solid",
	"el-icon-video-camera",
	"el-icon-message-solid",
	"el-icon-bell",
	"el-icon-s-cooperation",
	"el-icon-s-order",
	"el-icon-s-platform",
	"el-icon-s-fold",
	"el-icon-s-unfold",
	"el-icon-s-operation",
	"el-icon-s-promotion",
	"el-icon-s-home",
	"el-icon-s-release",
	"el-icon-s-ticket",
	"el-icon-s-management",
	"el-icon-s-open",
	"el-icon-s-shop",
	"el-icon-s-marketing",
	"el-icon-s-flag",
	"el-icon-s-comment",
	"el-icon-s-finance",
	"el-icon-s-claim",
	"el-icon-s-custom",
	"el-icon-s-opportunity",
	"el-icon-s-data",
	"el-icon-s-check",
	"el-icon-s-grid",
	"el-icon-menu",
	"el-icon-share",
	"el-icon-rank",
	"el-icon-view",
	"el-icon-c-scale-to-original",
	"el-icon-date",
	"el-icon-folder",
	"el-icon-tickets",
	"el-icon-document-remove",
	"el-icon-document-delete",
	"el-icon-document-copy",
	"el-icon-document",
	"el-icon-printer",
	"el-icon-paperclip",
	"el-icon-monitor",
	"el-icon-mobile",
	"el-icon-umbrella",
	"el-icon-data-line",
	"el-icon-coordinate",
	"el-icon-magic-stick",
	"el-icon-pie-chart",
	"el-icon-suitcase",
	"el-icon-suitcase-1",
	"el-icon-receiving",
	"el-icon-shopping-cart-full",
	"el-icon-shopping-cart-1",
	"el-icon-shopping-cart-2",
	"el-icon-office-building",
	"el-icon-shopping-bag-2",
	"el-icon-shopping-bag-1",
	"el-icon-present",
	"el-icon-coin",
	"el-icon-bank-card",
	"el-icon-discount",
	"el-icon-postcard",
	"el-icon-bangzhu",
	"el-icon-first-aid-kit",
	"el-icon-location",
	"el-icon-alarm-clock",
	"el-icon-lock"
]

export const btnStyleListArr=[
	{name:"常规",list:[
		{type:"默认",list:[
			{name:"默认按钮",type:"",plain:false},
			{name:"主要按钮",type:"primary",plain:false},
			{name:"成功按钮",type:"success",plain:false},
			{name:"信息按钮",type:"info",plain:false},
			{name:"警告按钮",type:"warning",plain:false},
			{name:"危险按钮",type:"danger",plain:false},
			{name:"我的按钮",type:"custom",plain:false},
		]},
		{type:"朴素",list:[
			{name:"默认按钮",type:"",plain:true},
			{name:"主要按钮",type:"primary",plain:true},
			{name:"成功按钮",type:"success",plain:true},
			{name:"信息按钮",type:"info",plain:true},
			{name:"警告按钮",type:"warning",plain:true},
			{name:"危险按钮",type:"danger",plain:true},
			{name:"我的按钮",type:"custom",plain:true},
		]}
	]},
	{name:"圆角",list:[
		{type:"默认",list:[
			{name:"默认按钮",type:"",plain:false},
			{name:"主要按钮",type:"primary",round:true,plain:false},
			{name:"成功按钮",type:"success",round:true,plain:false},
			{name:"信息按钮",type:"info",round:true,plain:false},
			{name:"警告按钮",type:"warning",round:true,plain:false},
			{name:"危险按钮",type:"danger",round:true,plain:false},
			{name:"我的按钮",type:"custom",round:true,plain:false},
		]},
		{type:"朴素",list:[
			{name:"默认按钮",type:"",plain:true},
			{name:"主要按钮",type:"primary",round:true,plain:true},
			{name:"成功按钮",type:"success",round:true,plain:true},
			{name:"信息按钮",type:"info",round:true,plain:true},
			{name:"警告按钮",type:"warning",round:true,plain:true},
			{name:"危险按钮",type:"danger",round:true,plain:true},
			{name:"我的按钮",type:"custom",round:true,plain:true},
		]}
	]},
	{name:"圆形",list:[
		{type:"默认",list:[
			{name:"",type:"",icon:"el-icon-search",circle:true,plain:false},
			{name:"",type:"primary",icon:"el-icon-edit",circle:true,plain:false},
			{name:"",type:"success",icon:"el-icon-check",circle:true,plain:false},
			{name:"",type:"info",icon:"el-icon-message",circle:true,plain:false},
			{name:"",type:"warning",icon:"el-icon-star-off",circle:true,plain:false},
			{name:"",type:"danger",icon:"el-icon-delete",circle:true,plain:false},
			{name:"",type:"custom",icon:"el-icon-plus",circle:true,plain:false},
		]},
		{type:"朴素",list:[
			{name:"",type:"",icon:"el-icon-search",circle:true,plain:true},
			{name:"",type:"primary",icon:"el-icon-edit",circle:true,plain:true},
			{name:"",type:"success",icon:"el-icon-check",circle:true,plain:true},
			{name:"",type:"info",icon:"el-icon-message",circle:true,plain:true},
			{name:"",type:"warning",icon:"el-icon-star-off",circle:true,plain:true},
			{name:"",type:"danger",icon:"el-icon-delete",circle:true,plain:true},
			{name:"",type:"custom",icon:"el-icon-plus",circle:true,plain:true},
		]}
	]},
]