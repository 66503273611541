<!-- 权限管理页面 -->
<template>
	<div class="pages" id="generalList">
		<div class="menu-item-box">
			<el-row :gutter="20">
				<!-- 第一级菜单 -->
				<transition name="el-fade-in-linear">
					<el-col :span="6">
						<right-item
							:menuList="menuList"
							:showBtnHandle="false"
							@editMenu="editMenu"
							@delMenu="delMenu"
							@handleBtn="handleBtn"
							@toSubMenu="toSubMenu"
							@addMenu="addMenu">
						</right-item>
					</el-col>
				</transition>
				<!-- 第二级菜单 -->
				<transition name="el-fade-in-linear" v-if="showSubMenu">
					<el-col :span="6">
						<right-item
							:menuList="subMenuList"
							:title="subMenuTitle"
							:itemIndex="1"
							:showBtnHandle="menuLevel==3?false:true"
							:showToSubHandle="menuLevel==2?false:true"
							@editMenu="editMenu"
							@delMenu="delMenu"
							@handleBtn="handleBtn"
							@toSubMenu="toThirdMenu"
							@addMenu="addMenu">
						</right-item>
					</el-col>
				</transition>
				<!-- 第三级菜单 -->
				<transition name="el-fade-in-linear" v-if="menuLevel==3">
					<el-col :span="6" v-if="showThirdMenu">
						<right-item
							:menuList="thirdMenuList"
							:title="thirdMenuTitle"
							:itemIndex="2"
							:showToSubHandle="false"
							@editMenu="editMenu"
							@delMenu="delMenu"
							@handleBtn="handleBtn"
							@addMenu="addMenu">
						</right-item>
					</el-col>
				</transition>
			</el-row>
		</div> 
		<!-- 菜单编辑 -->
		<el-dialog :title="title" :visible.sync="showDialog" width="46%">
			<el-form label-width="100px" :model="formDataProp" :rules="menuRules" ref="formDataProp">
				<el-row :gutter="20">
					<el-col :sm="22" :md="11" v-for="(item, index) in formConfig" v-bind:key="index">
						<el-form-item :label="item.label + '：'" :prop="item.prop">
							<el-input
								v-if="item.type == 'number'"
								type="number"
								v-model="formDataProp[item.prop]"
								auto-complete="off"
								:placeholder="'请输入' + item.label"
							></el-input>
							<el-input v-else-if="item.prop == 'type'" disabled value="菜单" auto-complete="off"></el-input>
							<el-select v-else-if="item.type == 'select'" v-model="formDataProp[item.prop]" placeholder="请选择">
								<el-option v-for="(sitem, sindex) in item.option" :key="sindex" :label="sitem.label" :value="sitem.value"></el-option>
							</el-select>
							<el-input v-else-if="item.prop == 'icon'" v-model="formDataProp[item.prop]" auto-complete="off" :placeholder="'请输入或选择' + item.label" @focus="showIconList=true"></el-input>
							<el-input v-else v-model="formDataProp[item.prop]" auto-complete="off" :placeholder="'请输入' + item.label"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="showDialog = false">取消</el-button>
				<el-button size="small" type="primary" class="title" @click="submitForm">保存</el-button>
				<div class="icon-area" v-if="showIconList">
					<happy-scroll color="#7a7a00" hide-horizontal size="2" resize>
						<ul>
							<li :class="currentIndex==index?'active':''" v-for="(item,index) in iconListArr" @click="selectIcon(item,index)" :key="index">
								<i :class="item"></i>
							</li>
						</ul>
					</happy-scroll>
				</div>
			</div>
		</el-dialog>
		<!-- 按钮编辑 -->
		<el-dialog title="操作按钮" :visible.sync="showBtnDialog" width="35%">
			<div class="btn-area">
				<div class="btn-area-item" v-for="(item, index) in btnArrList" :key="index">
					<el-button size="small" :type="JSON.parse(item.icon)['type']" plain @click="editBtn(item)">{{item.name}}</el-button>
					<span class="del" @click="delBtn(item)">x</span>
				</div>
				<div class="btn-area-item"><el-button size="small" type="warning" icon="el-icon-circle-plus-outline" @click="addNewBtn"></el-button></div>
			</div>
			<h5 class="btn-tips-wran" v-if="warningTest">{{warningTest}}</h5>
			<div class="btn-dialog" v-if="showAddBtnForm">
				<el-form label-width="100px" :model="btnFormDataProp" :rules="btnRules" ref="btnFormDataProp">
					<el-row :gutter="20">
						<el-col :sm="22" :md="11" v-for="(item, index) in btnFormConfig" v-bind:key="index">
							<el-form-item :label="item.label + '：'" :prop="item.prop">
								<el-input
									v-if="item.type == 'number'"
									type="number"
									v-model="btnFormDataProp[item.prop]"
									auto-complete="off"
									:placeholder="'请输入' + item.label"
								></el-input>
								<el-input v-else-if="item.prop == 'type'" disabled value="按钮" auto-complete="off"></el-input>
								<el-select v-else-if="item.type == 'selectInput'" filterable allow-create v-model="btnFormDataProp[item.prop]" placeholder="请选择">
									<el-option v-for="(sitem, sindex) in item.option" :key="sindex" :label="sitem" :value="sitem"></el-option>
								</el-select>
								<el-select v-else-if="item.type == 'select'" v-model="btnFormDataProp[item.prop]" placeholder="请选择">
									<el-option v-for="(sitem, sindex) in item.option" :key="sindex" :label="sitem.label" :value="sitem.value"></el-option>
								</el-select>
								<el-input v-else-if="item.prop == 'icon'" v-model="btnFormDataProp[item.prop]" readonly auto-complete="off" :placeholder="'请选择' + item.label" @focus="showBtnTypeList=true"></el-input> <!-- @blur="showBtnTypeList=false" -->
								<el-input v-else v-model="btnFormDataProp[item.prop]" auto-complete="off" :placeholder="'请输入' + item.label"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="showBtnDialog = false">取消</el-button>
				<el-button size="small" type="primary" class="title" @click="submitBtnForm">保存</el-button>
				<div class="btn-list-area" v-if="showBtnTypeList">
					<div class="list-area-item" v-for="(item,index) in btnStyleListArr" :key="index">
						<div class="item-box">
							<h5>{{item.name}}</h5>
							<div class="btn-list">
								<el-row v-for="(subitem,subindex) in item.list" :key="subindex">
									<el-button 
										v-for="(thirditem,thirdindex) in subitem.list" :key="thirdindex"
										:type="thirditem.type"
										:plain="thirditem.plain || false"
										:round="thirditem.round || false"
										:circle="thirditem.circle || false"
										:icon="thirditem.icon || ''"
										@click="selectBtnStyle(thirditem)">
										{{thirditem.name}}
									</el-button>
								</el-row>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {menuFormConfig,btnFormConfig,menuFormDataProp,btnFormDataProp,menuRules,btnRules,iconListArr,btnStyleListArr } from "../assets/config/system/right.js"
export default {
	data() {
		return {
			menuLevel:2,//至少2级，默认2,最多3级
			currentIndex:"s",
			showSubMenu: false,
			showThirdMenu: false,
			showDialog: false,
			showBtnDialog: false,
			showAddBtnForm:false,
			showIconList:false,
			showBtnTypeList:false,
			hasSub:false,//判断是否有子菜单
			hasBtn:false,//判断是否有操作按钮
			subMenuList: [],
			thirdMenuList: [],
			btnArrList: [],
			navigationList:[{name:"权限",uri:"/Right",icon:"el-icon-setting"}],
			parentId: '',
			subParentId: '',
			title: '新建菜单',
			subMenuTitle:"二级菜单",
			thirdMenuTitle:"三级菜单",
			warningTest:"",
			addType: "", //0,1,2菜单新建类别，0一级菜单，1二级菜单，2三级菜单
			btnAddType:"",//0,1,2按钮新建类别，0一级菜单，1二级菜单，2三级菜单
			type2:"",
			type3:"",
			formDataProp:menuFormDataProp,
			btnFormDataProp:btnFormDataProp,
			formConfig:menuFormConfig,
			btnFormConfig:btnFormConfig,
			menuRules:menuRules ,
			btnRules:btnRules,
			menuList:[],
			iconListArr:iconListArr,
			btnStyleListArr:btnStyleListArr
		};
	},
	computed: {
		...mapState({
			userData: state => state.user.userData,
			isLogin: state => state.user.isLogin
		})
	},
	components:{
		RightItem:() => import('@/components/rightItem.vue'),
	},
	mounted() {
		this.getMenuList();
	},
	methods: {
		addMenu(type) {
			if(this.hasBtn && type==this.type2){
				return this.$message.warning("当前菜单包含操作按钮，请先删除操作按钮再添加子菜单！")
			}
			this.currentIndex = "s"
			this.showIconList = false
			this.title="新建菜单"
			this.showDialog = true;
			this.addType = type;
			this.formDataProp = {
				name: '',
				icon: '',
				uri: '',
				sort: 0,
				type: 1,
				pid:type==1 ? this.parentId:type==2 ? this.subParentId : "0",
				permission: 1,
				status: 1
			};
			// this.formDataProp.type=1//0->目录；1->菜单；2->按钮（
			// this.formDataProp.status = 1//0->禁用；1->启用
		},
		editMenu(item) {
			console.log(item)
			this.showDialog = true;
			this.formDataProp = item;
			this.title="编辑菜单"
			this.currentIndex = "s"
			this.showIconList = false
		},
		delMenu(e) {
			const [item,addType] = e
			this.addType = addType;
			this.delFunc(item,()=>{
				if(this.addType == 0){
					this.showSubMenu = false
					this.showThirdMenu = false
				}
				if(this.addType == 1){
					this.showThirdMenu = false
				}
			})
		},
		toSubMenu(item) {
			this.subMenuTitle = item.name
			this.addType = 1
			this.type2 = 1
			this.showSubMenu = true;
			this.showThirdMenu = false;
			this.hasBtn = false
			this.hasSub = false
			this.parentId = item.id;
			this.subMenuList = []
			if ('children' in item && item.children[0].type == 1) {
				this.subMenuList = item.children;
				this.hasSub = true
			}
			if ('children' in item && item.children[0].type == 2) {
				this.hasBtn = true
			}
		},
		toThirdMenu(item) {
			this.thirdMenuTitle = item.name
			this.addType = 2
			this.type2 = 2
			this.showThirdMenu = true;
			this.hasBtn = false
			this.hasSub = false
			this.subParentId = item.id;
			this.thirdMenuList = [];
			if ('children' in item && item.children[0].type == 1) {
				this.thirdMenuList = item.children;
				this.hasSub = true
			}
			if ('children' in item && item.children[0].type == 2) {
				this.hasBtn = true
			}
		},
		async getMenuList(pid) {
			const res = await this.$axios('GET', '/sys/sysPermission/list', {}, true);
			this.menuList = res;
			if(this.addType == 0){
				this.subMenuList = []
				this.thirdMenuList = []
			}
			if (this.addType == 1 || this.btnAddType == 0) {
				this.menuList.map(v => {
					if (v.id == pid) {
						if("children" in v && v.children[0].type==1){
							this.subMenuList = v.children;
						}else{
							this.subMenuList = []
						}
						if("children" in v && v.children[0].type==2)
							this.btnArrList = v.children;
						else
							this.btnArrList = [];
					}
				});
			}
			if (this.addType == 2 || this.btnAddType == 1) {
				this.menuList.map(v => {
					if (v.id == this.parentId && "children" in v) {
						this.subMenuList = v.children;
						this.subMenuList.map(subv => {
							if (subv.id == pid) {
								if("children" in subv && subv.children[0].type==1)
									this.thirdMenuList = subv.children;
								else
									this.thirdMenuList = []
								if("children" in subv && subv.children[0].type==2)
									this.btnArrList = subv.children;
								else
									this.btnArrList = []
							}
						});
					}
				});
			}
			if (this.btnAddType == 2) {
				this.menuList.map(v => {
					if (v.id == this.parentId && "children" in v) {
						this.subMenuList = v.children;
						this.subMenuList.map(subv => {
							if (subv.id == this.subParentId && "children" in subv) {
								this.thirdMenuList = subv.children;
								this.thirdMenuList.map(thirdv =>{
									if(thirdv.id == pid){
										if("children" in thirdv && thirdv.children[0].type==2)
											this.btnArrList = thirdv.children;
										else
											this.btnArrList = []
									}
								})
							}else{
								this.thirdMenuList = []
							}
						});
					}else{
						this.subMenuList = []
					}
				});
			}
		},
		selectIcon(item,index){
			this.formDataProp.icon = item
			this.currentIndex = index
			// this.showIconList = false
		},
		submitForm() {
			this.submitFunc("formDataProp",this.formDataProp,()=>{
				this.showDialog = false;
				if(this.addType == 0){
					this.showSubMenu = false
					this.showThirdMenu = false
				}
				if(this.addType == 1){
					this.showThirdMenu = false
				}
			})
		},
		//按钮操作
		handleBtn(e) {
			const [item,type] = e
			this.btnAddType = type
			this.btnArrList = []
			this.warningTest = ""
			this.hasSub = false
			this.hasBtn = false
			this.showAddBtnForm = false
			this.btnParentId = item.id
			if ('children' in item && item.children[0].type == 1) {
				this.hasSub = true
			}
			if ('children' in item && item.children[0].type == 2) {
				this.btnArrList = item.children;
				this.hasBtn = true
			}
			this.showBtnDialog = true;
		},
		addNewBtn(){
			if(this.hasSub){
				return this.warningTest = "当前菜单包含子菜单，请在子菜单上添加操作按钮或先移除子菜单！"
			}
			this.btnFormDataProp = {
				name: '',
				icon: '',
				uri: '',
				pid: this.btnParentId,
				sort: 0,
				type: 2,
				status: 1,
				permission: 1
			}
			this.showAddBtnForm = true
		},
		editBtn(item){
			this.btnFormDataProp = item
			this.showAddBtnForm = true;
		},
		delBtn(item){
			this.delFunc(item,()=>{
				if(this.btnAddType == 0){
					this.showSubMenu = false
					this.showThirdMenu = false
				}
				if(this.btnAddType == 1){
					this.showThirdMenu = false
				}
			})
		},
		selectBtnStyle(item){
			// const {type,icon,circle,round,plain} = item
			// this.btnFormDataProp.icon = `${type || null},${icon || null},${circle || null},${round || null},${plain || null}`
			this.btnFormDataProp.icon = JSON.stringify(item)
		},
		submitBtnForm() {
			if(this.hasSub) return;
			this.submitFunc("btnFormDataProp",this.btnFormDataProp,()=>{
				this.showAddBtnForm = false;
				if(this.btnAddType == 0){
					this.showSubMenu = false
					this.showThirdMenu = false
				}
				if(this.btnAddType == 1){
					this.showThirdMenu = false
				}
			})
		},
		submitFunc(ref,param,callback){
			this.$refs[ref].validate(async valid => {
				if (valid) {
					await this.$axios('POST', '/sys/sysPermission/savaOrupdate', param, true);
					this.$message.success('保存成功！');
					this.$vue.$emit("refreshMenuList")
					callback()
					this.getMenuList(param.pid);
				} else {
					this.$message.error('请完善数据再重试！');
					return false;
				}
			});
		},
		delFunc(item,callback){
			this.$confirm('是否删除' + item.name, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(async () => {
				await this.$axios('POST', '/sys/sysPermission/delete?id=' + item.id, {}, true);
				this.$message.success('删除成功！');
				this.$vue.$emit("refreshMenuList")
				callback()
				this.getMenuList(item.pid);
			})
			.catch(() => {
				this.$message.info('已取消删除');
			});
		}
	}
};
</script>

<style lang="scss" >
.menu-item-box {
	margin-top: 40px;
	min-width: 1400px;
	.father-item{
		position: relative;
		.sub-item{
			border: 1px solid blue;width: 200px;height: 300px;
			position: absolute;
			z-index: 99;
			top: 0;
		}
	}
}
.menu-item-style {
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	.menu-item-type {
		color: #333;
		font-size: 16px;
		font-weight: bold;
		width: 100%;
		text-align: center;
		margin: 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 6px 0;
	}
	ul {
		height: 600px;
		min-width: 300px;
		padding: 16px 26px 34px 26px;
		box-sizing: border-box;
		overflow-y: auto;
	}
	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		list-style: none;
		color: #444;
		font-size: 16px;
		box-sizing: border-box;
		padding: 12px 0;
		border-bottom: 1px solid #f0f0f0;
		.el-icon-color {
			color: #67c23a;
			font-size: 12px;
			margin-right: 8px;
		}
		span {
			margin-right: 30px;
		}
		.menu-item-title {
			display: flex;
			align-items: center;
		}
		.menu-item-handel {
			i {
				margin-left: 20px;
				cursor: pointer;
				font-size: 16px;
			}
			.el-icon-edit {
				color: #909399;
			}
			.el-icon-delete {
				color: #f56c6c;
			}
		}
	}
}
.menu-btn {
	padding: 6px 0;
	text-align: center;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.btn-area {
	display: flex;
	flex-wrap: wrap;
	.btn-area-item {
		position: relative;
		box-sizing: border-box;
		padding: 0;
		height: 32px;
		margin-right: 14px;
		margin-bottom: 14px;
		.del {
			box-sizing: border-box;
			position: absolute;
			text-align: center;
			z-index: 9;
			top: -6px;
			right: -4px;
			background-color: #f56c6c;
			width: 12px;
			height: 12px;
			padding: 0;
			line-height: 8px;
			font-size: 2px;
			color: #fff;
			border-radius: 50%;
			cursor: pointer;
		}
	}
}
.btn-tips-wran{
	color: #fa5151;
	padding: 10px 0 0 0;
}
.btn-dialog{
	margin-top: 40px;
}
.icon-area{
	position: absolute;
	@include boxsizing;
	padding: 4px;
	z-index: 9999999;
	border-top: 1px solid #ededed;
	background-color: #fff;
	left: 0;
	right: 0;
	margin-top: 20px;
	height: 220px;
	overflow-y: auto;
	.happy-scroll-container {
		width: 100% !important;
		height: 100% !important;
		.happy-scroll-content {
			width: 100%;
		}
	}
	ul{
		margin: 0;
		padding: 6px;
		width: 100%;
		@include flex-style(row,wrap,flex-start,center);
		li{
			@include flex-style(column,nowrap,center,center);
			@include boxsizing;
			list-style: none;
			padding:8px;
			margin-left: 3px;
			margin-top: 3px;
			background-color: #ededed;
			border: 1px solid #999;
			cursor: pointer;
			i{
				font-size: 20px;
			}
			span{
				font-size: 10px;
			}
		}
		li.active{
			background-color: #A1B76E;
			color: #fff;
		}
	}
}
.btn-list-area{
	position: absolute;
	@include boxsizing;
	text-align: left;
	padding: 10px 10px 0;
	z-index: 9999999;
	border-top: 1px solid #ededed;
	background-color: #fff;
	left: 0;
	right: 0;
	margin-top: 20px;
	height: 260px;
	overflow-y: auto;
	.happy-scroll-container {
		width: 100% !important;
		height: 100% !important;
		.happy-scroll-content {
			width: 100%;
		}
	}
	.list-area-item{
		margin-bottom: 8px;
		&:last-child{
			margin-bottom: 0;
		}
		.item-box{
			@include flex-style(row,nowrap,flex-start,flex-start);
			h5{
				font-size: 14px;
				font-weight: bold;
				color: #999;
				margin-right: 14px;
				margin-left: 14px;
			}
			.btn-list{
				@include flex-style(column,nowrap,flex-start,center);
				.el-button{
					padding: 8px;
					margin-right: 10px;
					margin-bottom: 6px;
					font-size: 10px;
				}
				.el-button+.el-button{
					margin-left: 0;
				}
				.el-button.is-circle{
					height: 30px;
					width: 30px;
				}
			}
		}
	}
}
</style>
